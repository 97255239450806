import type { SystemCssProperties } from '@mui/system'
import React, { useRef, useEffect, useState, useMemo } from 'react'
import * as d3 from 'd3'
import { curveBundle } from 'd3-shape'
import { format, min, max, timeFormat, ascending, sum } from 'd3'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import { useSearchParams, usePathname } from 'next/navigation'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import ListSubheader from '@mui/material/ListSubheader'
import PercentIcon from '@mui/icons-material/Percent'
import Button from '@mui/material/Button'
import LinkIcon from '@mui/icons-material/Link'
import { dateDiffFloat, formatDateString, dateAdd } from '../../lib/utils/formatters'
import { useConstantsContext } from '../../lib/contexts/ConstantsContext'
import { useBreakPoints } from '../../lib/hooks'
import { objectToURLSearchParams, URLSearchParamsToObject } from '@/lib/routing/searchParams'

type TimeSeriesPlotProps = {
    gameData: DTO.PlayerStats[] | undefined
    seasonData: DTO.PlayerStats[] | undefined
    averages: DTO.GameStatThresholds | undefined
    curLeague: Enum.League
    desktopWidth: number
    loading: boolean
    statType: 'BASIC' | 'SHOOTING'
    plotMetrics: DTO.MetricMap[]
    showSubCategories?: boolean
    initStatFormat?: Enum.StatFormat
    selectedPossessions?: number
}

export const appendBBox = (
    bbox: { x: number; y: number; width: number; height: number },
    tip: d3.Selection<SVGGElement, unknown, null, undefined>,
    opacity?: number,
    stroke?: string,
    fill?: string,
    px?: number,
    py?: number
): void => {
    tip.append('rect')
        .attr('class', 'toolTip')
        .attr('x', bbox.x - (px || 10) / 2)
        .attr('y', bbox.y - (py || 8) / 2)
        .attr('width', bbox.width + (px || 10))
        .attr('height', bbox.height + (py || 8))
        .style('fill', fill || `rgba(250,250,250, ${opacity || 1})`)
        .style('stroke', stroke || '#003060')
}

const defaultCalc = (d: DTO.StatColumns[], metric: DTO.Metric) =>
    sum(d.map((g) => g[metric])) / d.map((g) => g[metric]).length

const toolTipVertOffset = 10
const toolTipHorizOffset = 15

export const listSubHeaderStyles: SystemCssProperties = {
    backgroundColor: 'grey.200',
    borderBottom: '1px solid',
    borderTop: '1px solid',
    borderColor: 'divider',
    lineHeight: '34px',
}

const TimeSeriesPlot = ({
    gameData,
    seasonData,
    averages,
    curLeague,
    desktopWidth,
    loading,
    statType,
    plotMetrics,
    selectedPossessions = 1,
    showSubCategories = true,
    initStatFormat = 'SEASON_STATS',
}: TimeSeriesPlotProps): JSX.Element => {
    const plotRef = useRef<SVGSVGElement>(null)
    const searchParams = useSearchParams()
    const pathname = usePathname()
    const { isMobile } = useBreakPoints()
    const [showAverages, setShowAverages] = useState(true)
    const [showRollingAverage, setShowRollingAverage] = useState(true)
    const [metric, setMetric] = useState<DTO.Metric>(
        plotMetrics.length === 1 ? plotMetrics[0].metric : statType === 'BASIC' ? 'ptsPerGame' : 'eFG'
    )

    useEffect(() => {
        const urlMetric = searchParams.get('metric') as DTO.Metric | undefined | null
        if (!!urlMetric && plotMetrics.map((d) => d.metric).includes(urlMetric)) {
            setMetric(urlMetric)
        }
        // Don't want searchParams as dependency because it will cause infinite loop
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setMetric, loading])

    const parentWidth = plotRef.current?.parentElement?.clientWidth
    const [svgWidth, setSVGWidth] = useState(parentWidth || desktopWidth)
    const svgHeight = min([svgWidth / 2.5, 300]) || 300
    const [rollNum, setRollNum] = useState<number | 'None'>(10)
    const { statsYear } = useConstantsContext()

    const plotType = initStatFormat === 'GAME_LOG' ? 'GAME_LOG' : searchParams.get('statFormat') || 'SEASON_STATS'
    const maxSeason = searchParams.get('maxSeason') || statsYear

    const margin = {
        top: plotType === 'GAME_LOG' ? 30 : 10,
        right: 20,
        bottom: isMobile ? 20 : 40,
        left: isMobile ? 30 : 40,
    }

    useEffect(() => {
        if (metric === 'ftPercGame' && plotType === 'SEASON_STATS') {
            setMetric('ftPercSeason')
        }
        if (metric === 'ftPercSeason' && plotType === 'GAME_LOG') {
            setMetric('ftPercGame')
        }
        if ((metric === 'predThreePtMade' || metric === 'predThreePtPerc') && plotType === 'GAME_LOG') {
            setMetric('eFG')
        }
    }, [metric, plotType])

    let minDate = String(searchParams.get('minDate') || `${Number(maxSeason)}-10-15`)
    let maxDate = String(searchParams.get('maxDate') || formatDateString(undefined, 'YYYY-MM-DD', 'local'))
    const {
        rollingCalc,
        calcNaN,
        isPercentage,
        abbr: metricAbbrRaw,
        format: metricFormat,
        category,
    } = plotMetrics.find((d) => d.metric === metric) as DTO.MetricMap

    const possessions = category === 'perOpp' ? selectedPossessions : 1

    const metricAbbr = category === 'perOpp' ? `${metricAbbrRaw} Per ${possessions}` : metricAbbrRaw

    const metricString = (
        metric.includes('Total') && plotType === 'GAME_LOG'
            ? metric.replace('Total', 'PerGame')
            : metric === 'ftPercSeason' || metric === 'ftPercGame'
            ? 'ftPerc'
            : metric === 'eFG'
            ? 'efg'
            : metric
    ) as DTO.Metric

    const perc = averages && metricString in averages && averages[metricString]
    const lastRow =
        plotType === 'GAME_LOG' ? gameData?.filter((d) => d.rowId !== 'Total')?.slice(-1) : seasonData?.slice(-1)

    let percLeague = curLeague
    if (lastRow?.length) {
        if (lastRow[0]?.league) {
            percLeague = lastRow[0].league
        } else if (lastRow[0]?.partialSeasons?.length) {
            if (lastRow[0]?.partialSeasons[0].league === 'FIBA') {
                percLeague = 'International'
            } else {
                percLeague = lastRow[0]?.partialSeasons[0].league || curLeague
            }
        }
    }

    const percSeason = (lastRow?.length && lastRow[0]?.season ? lastRow[0].season : maxSeason) as string
    const rawPercentiles: DTO.Percentiles | undefined =
        averages && perc ? perc[`${percLeague}-${Number(percSeason)}`] : undefined

    const percentiles = rawPercentiles?.map((d) => d && d * possessions)
    const leagueAverage = percentiles?.[4]

    if (gameData) {
        const playerMin = min(gameData.filter((d) => d.rowId !== 'Total').map((d) => d.rowId))
        const playerMax = max(gameData.filter((d) => d.rowId !== 'Total').map((d) => d.rowId))
        if (playerMin && playerMax) {
            maxDate = playerMax
            minDate = dateAdd(playerMin, 'day', -5).toISOString()
        }
    }

    const [filteredGameData, filteredSeasonData, years, seasonLeagues] = useMemo(() => {
        const filteredGameDataMemo = gameData
            ?.filter(
                (d) =>
                    new Date(d.rowId) >= new Date(minDate) &&
                    new Date(d.rowId) <= new Date(maxDate) &&
                    d.rowId !== 'Total'
            )
            .sort((a, b) => ascending(new Date(a.rowId), new Date(b.rowId)))

        let filteredSeasonDataMemo: (DTO.StatsHeader & DTO.StatColumns)[] | undefined = seasonData ? [] : undefined

        const memoYears: string[] = []
        seasonData
            ?.sort((a, b) => Number(a.rowId) - Number(b.rowId))
            .filter((d) => d.rowId !== 'Total')
            .forEach((d) => {
                memoYears.push(String(d.rowId))
                if (d.teamId) {
                    filteredSeasonDataMemo?.push(d)
                } else {
                    filteredSeasonDataMemo = filteredSeasonDataMemo?.concat(d.partialSeasons as DTO.PlayerStats[])
                }
            })

        const memoSeasonLeagues: string[] = []
        for (let i = 0; i < memoYears.length; i += 1) {
            const filteredSeasonDataMemoYear = filteredSeasonDataMemo?.filter((d) => String(d.rowId) === memoYears[i])
            if (filteredSeasonDataMemoYear) {
                const leagues = Array.from(
                    new Set(
                        filteredSeasonDataMemoYear
                            .map((d) =>
                                d.league === 'G-League'
                                    ? 'GLG'
                                    : d.league === 'International'
                                    ? 'Intl'
                                    : d.league === 'College'
                                    ? 'NCAA'
                                    : d.league === 'Summer'
                                    ? 'SL'
                                    : d.league
                            )
                            .filter((d) => !!d)
                    )
                )

                if (new Set(leagues).size > 1) {
                    memoSeasonLeagues.push(`${memoYears[i]}_${leagues.join(', ')}`)
                } else if (new Set(leagues).size === 1) {
                    memoSeasonLeagues.push(`${memoYears[i]}_${leagues[0] as string}`)
                }
            }
        }
        // filteredSeasonDataMemo?.map((d) => ({ season: d.season, league: d.league })).filter((d) => !!d) || []

        return [filteredGameDataMemo, filteredSeasonDataMemo, memoYears, memoSeasonLeagues]
    }, [minDate, maxDate, seasonData, gameData])

    useEffect(() => {
        setSVGWidth(parentWidth || desktopWidth)
        function handleResize(this: Window) {
            // eslint-disable-next-line react/no-this-in-sfc
            const parentElWidth = plotRef.current?.parentElement?.clientWidth
            setSVGWidth(parentElWidth || desktopWidth)
        }
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [isMobile, desktopWidth, parentWidth])

    useEffect(() => {
        const width = svgWidth - margin.left - margin.right
        const height = svgHeight - margin.top - margin.bottom

        const xScaleGame = d3
            .scaleTime()
            .range([0, width])
            .domain([new Date(minDate), new Date(maxDate)])

        const xScaleSeason = d3.scaleBand().range([0, width]).domain(years).padding(0.7)
        const xScaleSeasonLeague = d3.scaleBand().range([0, width]).domain(seasonLeagues).padding(0.7)

        const timePeriod = dateDiffFloat(minDate, 'month', false, maxDate)
        const statData: DTO.StatData[] =
            filteredGameData && !loading
                ? filteredGameData.map((d) => {
                      const val = d[metric]
                      return {
                          date: d.rowId,
                          val: val && val * possessions,
                          playedGame: !!(d.gpTotal && d.gpTotal > 0),
                          isNaN: calcNaN ? !calcNaN(d) : false,
                          dnpReason:
                              !(d.gpTotal && d.gpTotal > 0) || d.dnpReason
                                  ? d.dnpInjury
                                      ? 'DNP Inj'
                                      : d.dnpRest
                                      ? 'DNP Rest'
                                      : 'DNP CD'
                                  : null,
                          matchup:
                              d.awayTeamShortName &&
                              d.homeTeamShortName &&
                              `${d.homeTeamId === d.teamId ? `vs ${d.awayTeamShortName}` : `@ ${d.homeTeamShortName}`}`,
                      }
                  })
                : []

        const plot = d3.select(plotRef.current)

        plot.selectAll('*').remove()
        const g = plot.append('g').attr('transform', `translate(${margin.left},${margin.top})`)
        const rolling: DTO.RollingAvg[] = []

        let yMax = percentiles?.[7] || 0
        let yMin = percentiles?.[1] || 0
        if (plotType === 'GAME_LOG' && filteredGameData && !loading) {
            const filteredRollingGameData = filteredGameData.filter((d) => !!d[metric] || d[metric] === 0)
            for (let j = 0; j < filteredRollingGameData.length; j += 1) {
                const rollingData = filteredRollingGameData.filter(
                    (d, i) =>
                        i <= j + (rollNum === 'None' ? 0 : rollNum) / 2 &&
                        i > j - (rollNum === 'None' ? 0 : rollNum) / 2
                )

                if (rollingData.length > 0) {
                    const rollingVal = rollingCalc
                        ? rollingCalc(rollingData) * possessions
                        : defaultCalc(rollingData, metric)
                    rolling.push({
                        date: filteredRollingGameData[j].rowId,
                        roll_val: rollingVal,
                    })
                }
            }
        }

        let metricMap = filteredSeasonData
            ?.map((d) => (d[metric] as number) * possessions)
            .filter((d) => !!d || d === 0)
        if (metricMap && plotType === 'SEASON_STATS') {
            const playerMax = max(metricMap)
            const playerMin = min(metricMap)

            yMax = (playerMax || playerMax === 0) && playerMax > yMax ? playerMax : yMax
            yMin = (playerMin || playerMin === 0) && playerMin < yMin ? playerMin : yMin
        }

        metricMap = filteredGameData
            ?.filter((d) => !d.dnpReason || d.gpTotal)
            .map((d) => (d[metric] as number) * possessions)
            .filter((d) => !!d || d === 0)
        if (metricMap && plotType === 'GAME_LOG') {
            const playerMax = max(metricMap)
            const playerMin = min(metricMap)
            yMax = (playerMax || playerMax === 0) && playerMax > yMax ? playerMax : yMax
            yMin = (playerMin || playerMin === 0) && playerMin < yMin ? playerMin : yMin
        }

        yMax += isPercentage ? 0.05 : Math.abs(yMax / 10)
        yMin -= isPercentage ? 0.05 : Math.abs(yMax / 10)

        const yScale = d3.scaleLinear().range([height, 0]).domain([yMin, yMax])
        g.append('g')
            .attr('id', 'yAxis')
            .call(
                d3
                    .axisLeft(yScale)
                    .ticks(5)
                    .tickFormat(format(isPercentage ? '.0%' : ''))
            )
            .style('font-size', '11px')

        g.selectAll('grid_lines')
            .data(yScale.ticks(8))
            .enter()
            .append('line')
            .attr('x1', 0)
            .attr('x2', width)
            .attr('y1', (d) => yScale(d) + 0.5)
            .attr('y2', (d) => yScale(d) + 0.5)
            .style('opacity', '.15')
            .style('stroke', 'gray')

        if (!!showAverages && percentiles) {
            const percentilesToPlot =
                percentiles[1] === percentiles[7] ||
                percentiles[4] === percentiles[7] ||
                percentiles[1] === percentiles[4] ||
                isMobile
                    ? [
                          {
                              label: `${percLeague} Lg Avg (${
                                  percentiles[4]
                                      ? format(metricFormat === '.0f' ? '.1f' : metricFormat)(percentiles[4])
                                      : '--'
                              })`,
                              val: percentiles[4],
                          },
                      ]
                    : [
                          {
                              label: `20% (${
                                  percentiles[1] || percentiles[1] === 0
                                      ? format(metricFormat === '.0f' ? '.1f' : metricFormat)(percentiles[1])
                                      : '--'
                              })`,
                              val: percentiles[1],
                          },
                          {
                              label: `${percSeason} ${percLeague} Lg Avg (${
                                  percentiles[4]
                                      ? format(metricFormat === '.0f' ? '.1f' : metricFormat)(percentiles[4])
                                      : '--'
                              })`,
                              val: percentiles[4],
                          },
                          {
                              label: `80% (${
                                  percentiles[7]
                                      ? format(metricFormat === '.0f' ? '.1f' : metricFormat)(percentiles[7])
                                      : '--'
                              })`,
                              val: percentiles[7],
                          },
                      ]
            // loc avg lines
            g.selectAll('locAvg')
                .data(percentilesToPlot)
                .enter()
                .append('line')
                .attr('x1', 0)
                .attr('x2', width)
                .attr('y1', (d) => yScale(d.val as number))
                .attr('y2', (d) => yScale(d.val as number))
                .style('stroke', 'black')
                .style('stroke-width', 0.5)
                .style('stroke-dasharray', '10 5')

            // lg avg line
            g.selectAll('locAvgText')
                .data(percentilesToPlot)
                .enter()
                .append('text')
                .text((d) => d.label)
                .attr('x', width)
                .attr('y', (d) => yScale(d.val as number) - 2)
                .style('fill', 'black')
                .style('font-size', '13px')
                .style('text-anchor', 'end')
        }

        if (plotType === 'SEASON_STATS') {
            g.append('g')
                .attr('transform', `translate(0,${height})`)
                .call(d3.axisBottom<string>(xScaleSeason))
                .style('font-size', '11px')

            if (!isMobile) {
                g.append('g')
                    .attr('transform', `translate(0,${height + 15})`)
                    .call(d3.axisBottom<string>(xScaleSeasonLeague).tickFormat((d) => d.split('_')[1]))
                    .call((d) => d.select('.domain').remove())
                    .call((d) => d.selectAll('.tick>line').remove())
                    .style('font-size', '11px')
            }
        }

        const translateToolTip = (
            bbox: { x: number; y: number; width: number; height: number },
            tip: d3.Selection<SVGGElement, unknown, null, undefined>,
            row: DTO.StatsHeader & DTO.StatColumns
        ) => {
            const tipRight = bbox.x + bbox.width
            const tipLeft = bbox.x
            const point = xScaleSeason(String(row.rowId)) as number
            if (point > tipRight) {
                tip.style('transform', `translate(${point - tipRight}px, 0px)`)
            } else if (point < tipLeft) {
                tip.style('transform', `translate(${point - tipLeft}px, 0px)`)
            }
        }

        const drawToolTip = (
            tip: d3.Selection<SVGGElement, unknown, null, undefined>,
            row: DTO.StatsHeader & DTO.StatColumns,
            val: number,
            total?: number | null | undefined
        ) => {
            tip.append('text')
                .attr('class', 'toolTip')
                .text(
                    `${row.rowId}${
                        row.teamShortName
                            ? ` - ${row.teamAbbr || row.teamShortName} ${row.league === 'Summer' ? '(SL)' : ''}  ${
                                  row.gameType && row.gameType !== 'REGULAR'
                                      ? ` - ${row.gameType
                                            .replace('PLAY_IN', row.league === 'College' ? 'Conf' : 'Play In')
                                            .replace('PRESEASON', 'Preseason')
                                            .replace('PLAYOFF', 'Playoffs')}`
                                      : ''
                              }`
                            : ''
                    }`
                )
                .attr('x', () => {
                    const x = xScaleSeason(String(row.rowId)) as number
                    return x > width / 2 ? x - 2 * toolTipHorizOffset : x + toolTipHorizOffset
                })
                .attr(
                    'y',
                    yScale(val) + (val < yScale.domain()[1] / 2 ? -5.5 * toolTipVertOffset : 3 * toolTipVertOffset)
                )
                .style('text-anchor', 'start')
                .style('fill', 'black')
                .style('font-size', '12px')
                .style('font-weight', 'bold')

            tip.append('text')
                .attr('class', 'toolTip')
                .text(
                    `${metricAbbr}: ${format(
                        metricFormat === '.0f' && metric.indexOf('PerGame') > -1
                            ? '.2f'
                            : metricFormat === '.1f' && metric.indexOf('gameScore') === -1
                            ? '.2f'
                            : metricFormat
                    )(val)}`
                )
                .attr('x', () => {
                    const x = xScaleSeason(String(row.rowId)) as number
                    return x > width / 2 ? x - 2 * toolTipHorizOffset : x + toolTipHorizOffset
                })
                .attr(
                    'y',
                    yScale(val) + (val < yScale.domain()[1] / 2 ? -3.5 * toolTipVertOffset : 5 * toolTipVertOffset)
                )
                .style('text-anchor', 'start')
                .style('fill', 'black')
                .style('font-size', '12px')

            if (total && statType === 'SHOOTING') {
                tip.append('text')
                    .attr('class', 'toolTip')
                    .text(`# Shots: ${total}`)
                    .attr('x', () => {
                        const x = xScaleSeason(String(row.rowId)) as number
                        return x > width / 2 ? x - 2 * toolTipHorizOffset : x + toolTipHorizOffset
                    })
                    .attr(
                        'y',
                        yScale(val) + (val < yScale.domain()[1] / 2 ? -1.5 * toolTipVertOffset : 7 * toolTipVertOffset)
                    )
                    .style('text-anchor', 'start')
                    .style('fill', 'black')
                    .style('font-size', '12px')
            }
        }

        if (plotType === 'SEASON_STATS' && filteredSeasonData && !loading) {
            g.selectAll('season_logo')
                .data(
                    filteredSeasonData
                        .filter((d) => !!d.teamId && !!d.teamLogoUrl && (d[metric] || d[metric] === 0))
                        .sort((a, b) => d3.ascending(a.season, b.season))
                )
                .enter()
                .append('image')
                .attr('x', (d) => (xScaleSeason(String(d.rowId)) as number) + xScaleSeason.bandwidth() / 2)
                .attr('y', (d) => yScale(Number(d[metric]) * possessions))
                .attr('xlink:href', (d) => d.teamLogoUrl as string)
                .attr('height', isMobile ? '26' : '36')
                .attr('width', isMobile ? '26' : '36')
                .attr('transform', 'translate(-18,-18)')
                .style('opacity', (d) =>
                    d.league === 'Summer' || d.league === 'G-League' || d.gameType !== 'REGULAR' ? 0.5 : 1
                )
                .on('mouseover', (_e, d) => {
                    const val = d[metric]
                    const total = calcNaN?.(d)
                    if (val || val === 0) {
                        const tip = g.append('g')

                        drawToolTip(tip, d, val * possessions, total)

                        const bbox = tip.node()?.getBBox()

                        if (bbox) {
                            appendBBox(bbox, tip)
                            drawToolTip(tip, d, val * possessions, total)
                            translateToolTip(bbox, tip, d)
                        }
                    }
                })
                .on('mouseout', () => {
                    g.selectAll('.toolTip').remove()
                })

            g.selectAll('season_avg')
                .data(filteredSeasonData.filter((d) => !!d.teamId && !d.teamLogoUrl))
                .enter()
                .append('circle')
                .attr('r', 7)
                .attr('cx', (d) => (xScaleSeason(String(d.rowId)) as number) + xScaleSeason.bandwidth() / 2)
                .attr('cy', (d) => yScale(Number(d[metric]) * possessions))
                .style('display', (d) => !d[metric] && 'none')
                .style('fill', (d) =>
                    (d.league === 'NBA' || d.league === 'G-League') && d.teamColor
                        ? d.teamColor
                        : d.league === 'Summer'
                        ? 'rgb(210,210,210)'
                        : 'gray'
                )
                .style('stroke', (d) => (d.league === 'Summer' && d.teamColor ? d.teamColor : 'black'))
                .on('mouseover', (_e, d) => {
                    const val = d[metric]
                    if (val || val === 0) {
                        const tip = g.append('g')

                        drawToolTip(tip, d, val * possessions)

                        const bbox = tip.node()?.getBBox()

                        if (bbox) {
                            appendBBox(bbox, tip)

                            drawToolTip(tip, d, val * possessions)

                            translateToolTip(bbox, tip, d)
                        }
                    }
                })
                .on('mouseout', () => {
                    g.selectAll('.toolTip').remove()
                })
        }

        if (plotType === 'GAME_LOG') {
            const line = d3
                .line<DTO.RollingAvg>()
                .x((d) => xScaleGame(new Date(d.date)))
                .y((d) => yScale(d.roll_val))
                .curve(curveBundle.beta(1.1))

            if (showRollingAverage && rollNum !== 'None' && rolling.length > rollNum) {
                g.append('path')
                    .datum(rolling)
                    .attr('fill', 'none')
                    .style('stroke', '#003060')
                    .attr('stroke-width', 2)
                    .attr('d', line)
                    .classed('roll', true)
                    .classed('rollLine', true)
                    .attr('y2', height)

                const mouseLine = g
                    .append('line')
                    .style('stroke-width', 1)
                    .style('stroke', 'black')
                    .style('opacity', 0)
                    .attr('y1', 0)
                    .attr('y2', height)

                const rollingDateText = g
                    .append('text')
                    .style('opacity', 0)
                    .style('font-size', '12px')
                    .style('fill', 'black')
                    .style('stroke', 'none')
                    .attr('y', 5)
                    .style('font-weight', 'bold')

                const rollingValText = g
                    .append('text')
                    .style('opacity', 0)
                    .style('font-size', '12px')
                    .style('fill', 'black')
                    .style('stroke', 'none')
                    .attr('y', 18)

                g.append('svg:rect')
                    .attr('width', width)
                    .attr('height', height)
                    .style('fill', 'none')
                    .attr('pointer-events', 'all')
                    .on('mousemove', (e: React.MouseEvent<HTMLButtonElement>) => {
                        const [x] = d3.pointer(e)
                        if (
                            x > line.x()(rolling[0], 0, rolling) &&
                            x < line.x()(rolling[rolling.length - 1], 0, rolling)
                        ) {
                            mouseLine.style('opacity', 1).attr('x1', x).attr('x2', x)
                            const rollingDate = xScaleGame.invert(x)
                            let nearestDateIdx = 0
                            let diff = 365
                            for (let i = 0; i < rolling.length; i += 1) {
                                const newDiff = dateDiffFloat(
                                    rolling[i].date,
                                    'day',
                                    false,
                                    formatDateString(rollingDate, 'YYYY-MM-DD', 'local')
                                )
                                if (Math.abs(newDiff) < Math.abs(diff)) {
                                    diff = newDiff
                                    nearestDateIdx = i
                                }
                            }

                            const rollingVal = yScale.invert(line.y()(rolling[nearestDateIdx], 0, rolling))
                            rollingDateText
                                .attr('x', x < width / 2 ? x + 5 : x - 5)
                                .text(formatDateString(rollingDate, 'M/D/YY', 'local'))
                                .style('opacity', 1)
                                .attr('text-anchor', x < width / 2 ? 'start' : 'end')

                            rollingValText
                                .attr('x', x < width / 2 ? x + 5 : x - 5)

                                .text(
                                    `${metricAbbr}: ${format(metricFormat === '.0f' ? '.1f' : metricFormat)(
                                        rollingVal
                                    )}`
                                )
                                .style('opacity', 1)
                                .attr('text-anchor', x < width / 2 ? 'start' : 'end')
                        } else {
                            mouseLine.style('opacity', 0)
                            rollingDateText.style('opacity', 0)
                            rollingValText.style('opacity', 0)
                        }
                    })
                    .on('mouseout', () => {
                        mouseLine.style('opacity', 0)
                        rollingDateText.style('opacity', 0)
                        rollingValText.style('opacity', 0)
                    })
            }

            g.append('g')
                .attr('transform', `translate(0,${height})`)
                .call(
                    d3
                        .axisBottom<Date>(xScaleGame)
                        .ticks(isMobile ? 5 : timePeriod > 5 ? 7 : 5)
                        .tickFormat(timeFormat(isMobile ? '%m/%y' : timePeriod > 3 ? "%b '%y" : '%d-%b'))
                )
                .style('font-size', isMobile ? '10px' : '11px')

            if ((leagueAverage || leagueAverage === 0) && showAverages) {
                g.selectAll('games_lines')
                    .data(statData.filter((d) => d.playedGame && (!!d.val || d.val === 0)))
                    .enter()
                    .append('line')
                    .attr('x1', (d) => xScaleGame(new Date(d.date)))
                    .attr('x2', (d) => xScaleGame(new Date(d.date)))
                    .attr('y1', yScale(leagueAverage))
                    .attr('y2', (d) => yScale(d.val as number))
                    .style('stroke', 'gray')
                    .style('opacity', '.3')
                    .style('stroke-width', 0.5)
            }

            g.selectAll('games_dots')
                .data(statData)
                .enter()
                .append('circle')
                .attr('r', 2.5)
                .attr('cx', (d) => xScaleGame(new Date(d.date)))
                .attr('cy', (d) =>
                    yScale(
                        d.playedGame && (d.val || d.val === 0)
                            ? d.val
                            : leagueAverage || leagueAverage === 0
                            ? leagueAverage
                            : yScale.domain()[0]
                    )
                )
                .style('fill', (d) => (!d.playedGame ? 'red' : d.isNaN ? 'blue' : 'gray'))
                .style('opacity', (d) => (!d.val ? 0.5 : 0.3))
                .style('cursor', 'pointer')
                .on('mouseover', (_e, d) => {
                    const { val: value, isNaN, playedGame, dnpReason } = d
                    const val = value || value === 0 ? value : leagueAverage
                    if (val || val === 0) {
                        const tip = g.append('g')
                        const drawToolTipGame = () => {
                            tip.append('text')
                                .attr('class', 'toolTip')
                                .text(`${formatDateString(d.date, 'M/D/YY', 'local')} ${d.matchup || ''}`)
                                .attr('x', () => {
                                    const x = xScaleGame(new Date(d.date))
                                    return x > width / 2 ? x - 6 * toolTipHorizOffset : x + toolTipHorizOffset
                                })
                                .attr(
                                    'y',
                                    yScale(val) +
                                        (val < yScale.domain()[1] / 2
                                            ? -3.5 * toolTipVertOffset
                                            : 3 * toolTipVertOffset)
                                )
                                .style('text-anchor', 'start')
                                .style('fill', 'black')
                                .style('font-size', '12px')
                                .style('font-weight', 'bold')

                            tip.append('text')
                                .attr('class', 'toolTip')
                                .text(
                                    `${metricAbbr}: ${
                                        isNaN || !playedGame ? dnpReason || '--' : format(metricFormat)(val)
                                    }`
                                )
                                .attr('x', () => {
                                    const x = xScaleGame(new Date(d.date))
                                    return x > width / 2 ? x - 6 * toolTipHorizOffset : x + toolTipHorizOffset
                                })
                                .attr(
                                    'y',
                                    yScale(val) +
                                        (val < yScale.domain()[1] / 2
                                            ? -1.5 * toolTipVertOffset
                                            : 5 * toolTipVertOffset)
                                )
                                .style('text-anchor', 'start')
                                .style('fill', 'black')
                                .style('font-size', '12px')
                        }
                        drawToolTipGame()
                        const bbox = tip.node()?.getBBox()
                        if (bbox) {
                            tip.append('rect')
                                .attr('class', 'toolTip')
                                .attr('x', bbox.x - 5)
                                .attr('y', bbox.y - 5)
                                .attr('width', bbox.width + 10)
                                .attr('height', bbox.height + 8)
                                .style('fill', 'rgb(250,250,250)')
                                .style('border-radius', '5px')
                                .style('stroke', '#003060')
                            drawToolTipGame()
                            const tipRight = bbox.x + bbox.width
                            const tipLeft = bbox.x
                            const point = xScaleGame(new Date(d.date))
                            if (point > tipRight) {
                                tip.style('transform', `translate(${point - tipRight}px, 0px)`)
                            } else if (point < tipLeft) {
                                tip.style('transform', `translate(${point - tipLeft}px, 0px)`)
                            }
                        }
                    }
                })
                .on('mouseout', () => {
                    g.selectAll('.toolTip').remove()
                })
        }

        if (plotType === 'GAME_LOG') {
            const legend = plot.append('g')

            if (statType === 'SHOOTING') {
                legend
                    .append('circle')
                    .attr('cx', 0)
                    .attr('cy', 10)
                    .attr('r', 4)
                    .style('stroke', 'lightgray')
                    .style('stroke-width', '.5')
                    .style('fill', 'blue')

                legend
                    .append('text')
                    .attr('x', 7)
                    .attr('y', 14)
                    .attr('r', 4)
                    .text('No Shots')
                    .style('font-size', '11px')
            }

            legend
                .append('circle')
                .attr('cx', 65)
                .attr('cy', 10)
                .attr('r', 4)
                .style('stroke', 'lightgray')
                .style('stroke-width', '.5')
                .style('fill', 'red')

            legend.append('text').attr('x', 72).attr('y', 14).text('DNP').style('font-size', '11px')
            if (!showSubCategories) {
                legend
                    .append('text')
                    .attr('x', -12)
                    .attr('y', 28)
                    .text('10 Game Rolling Avg')
                    .style('font-size', '11px')
                    .style('fill', '#003060')
                    .style('font-weight', 'bold')
            }

            legend.style('transform', `translate(${width - margin.right - margin.left - (isMobile ? 15 : 0)}px, 0px)`)
        }
    }, [
        plotType,
        showAverages,
        showRollingAverage,
        rollNum,
        metric,
        rollingCalc,
        isPercentage,
        isMobile,
        filteredGameData,
        filteredSeasonData,
        svgWidth,
        svgHeight,
        minDate,
        maxDate,
        years,
        metricAbbr,
        metricFormat,
        percentiles,
        leagueAverage,
        loading,
        averages,
        calcNaN,
        percLeague,
        percSeason,
        seasonLeagues,
        category,
        margin.left,
        margin.right,
        margin.top,
        margin.bottom,
        metricString,
        statType,
        possessions,
        showSubCategories,
    ])
    return (
        <Card
            variant="outlined"
            elevation={0}
            sx={{ padding: 1, border: showSubCategories ? undefined : '0px solid black' }}
        >
            <Box
                id="timeSeriesPlot"
                sx={{
                    marginBottom: '2px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexFlow: 'wrap',
                    width: '100%',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'space-between',
                        width: '100%',
                        marginX: '5px',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 1,
                        }}
                    >
                        {plotMetrics.length > 1 && (
                            <FormControl size="small">
                                <InputLabel id="showing-label">Metric</InputLabel>
                                {statType === 'BASIC' && (
                                    <Select
                                        sx={{
                                            textAlign: 'left',
                                            backgroundColor: 'common.white',
                                            minWidth: '105px',
                                            height: '33px',
                                        }}
                                        value={metric}
                                        disabled={loading}
                                        onChange={(e) => {
                                            setMetric(e.target.value as DTO.Metric)
                                        }}
                                        label="Metric"
                                        name="metric"
                                        MenuProps={{ sx: { maxHeight: 450, '.MuiMenu-list': { paddingTop: 0 } } }}
                                    >
                                        <ListSubheader sx={listSubHeaderStyles}>Per Game</ListSubheader>
                                        {plotMetrics
                                            .filter((x) => x.category === 'perGame')
                                            .map((m) => (
                                                <MenuItem sx={{ minHeight: '20px' }} key={m.metric} value={m.metric}>
                                                    {m.abbr}
                                                </MenuItem>
                                            ))}
                                        <ListSubheader sx={listSubHeaderStyles}>Percentages</ListSubheader>
                                        {plotMetrics
                                            .filter((x) => x.category === 'perc')
                                            .map((m) => (
                                                <MenuItem sx={{ minHeight: '20px' }} key={m.metric} value={m.metric}>
                                                    {m.abbr}
                                                </MenuItem>
                                            ))}

                                        <ListSubheader sx={listSubHeaderStyles}>
                                            Per {selectedPossessions}
                                        </ListSubheader>
                                        {plotMetrics
                                            .filter((x) => x.category === 'perOpp')
                                            .map((m) => (
                                                <MenuItem sx={{ minHeight: '20px' }} key={m.metric} value={m.metric}>
                                                    {m.abbr} Per {selectedPossessions}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                )}
                                {statType === 'SHOOTING' && (
                                    <Select
                                        sx={{
                                            textAlign: 'left',
                                            backgroundColor: 'common.white',
                                            minWidth: '105px',
                                            height: '33px',
                                        }}
                                        value={metric}
                                        disabled={loading}
                                        onChange={(e) => {
                                            setMetric(e.target.value as DTO.Metric)
                                        }}
                                        label="Metric"
                                        name="metric"
                                        MenuProps={{ sx: { maxHeight: 450, '.MuiMenu-list': { paddingTop: 0 } } }}
                                    >
                                        <ListSubheader sx={listSubHeaderStyles}>Overall</ListSubheader>
                                        {plotMetrics
                                            .filter(
                                                (x) =>
                                                    x.category === 'overall' ||
                                                    (x.category === 'overallSeason' && plotType === 'SEASON_STATS') ||
                                                    (x.category === 'overallGame' && plotType === 'GAME_LOG')
                                            )
                                            .map((m) => (
                                                <MenuItem sx={{ minHeight: '20px' }} key={m.metric} value={m.metric}>
                                                    {m.abbr}
                                                </MenuItem>
                                            ))}
                                        <ListSubheader sx={listSubHeaderStyles}>Efficiency</ListSubheader>
                                        {plotMetrics
                                            .filter((x) => x.category === 'efficiency')
                                            .map((m) => (
                                                <MenuItem sx={{ minHeight: '20px' }} key={m.metric} value={m.metric}>
                                                    {m.abbr}
                                                </MenuItem>
                                            ))}
                                        <ListSubheader sx={listSubHeaderStyles}>Selection</ListSubheader>
                                        {plotMetrics
                                            .filter((x) => x.category === 'selection')
                                            .map((m) => (
                                                <MenuItem sx={{ minHeight: '20px' }} key={m.metric} value={m.metric}>
                                                    {m.abbr}
                                                </MenuItem>
                                            ))}
                                        <ListSubheader sx={listSubHeaderStyles}>Shot Quality</ListSubheader>
                                        {plotMetrics
                                            .filter((x) => x.category === 'shotQuality')
                                            .map((m) => (
                                                <MenuItem sx={{ minHeight: '20px' }} key={m.metric} value={m.metric}>
                                                    {m.abbr}
                                                </MenuItem>
                                            ))}
                                        <ListSubheader sx={listSubHeaderStyles}>Shot Ability</ListSubheader>
                                        {plotMetrics
                                            .filter((x) => x.category === 'shotAbility')
                                            .map((m) => (
                                                <MenuItem sx={{ minHeight: '20px' }} key={m.metric} value={m.metric}>
                                                    {m.abbr}
                                                </MenuItem>
                                            ))}
                                        <ListSubheader sx={listSubHeaderStyles}>Contestedness Efficiency</ListSubheader>
                                        {plotMetrics
                                            .filter((x) => x.category === 'contestedness')
                                            .map((m) => (
                                                <MenuItem sx={{ minHeight: '20px' }} key={m.metric} value={m.metric}>
                                                    {m.abbr}
                                                </MenuItem>
                                            ))}
                                        <ListSubheader sx={listSubHeaderStyles}>Contestedness Selection</ListSubheader>
                                        {plotMetrics
                                            .filter((x) => x.category === 'contestednessSelection')
                                            .map((m) => (
                                                <MenuItem sx={{ minHeight: '20px' }} key={m.metric} value={m.metric}>
                                                    {m.abbr}
                                                </MenuItem>
                                            ))}
                                        <ListSubheader sx={listSubHeaderStyles}>Game State Efficiency</ListSubheader>
                                        {plotMetrics
                                            .filter((x) => x.category === 'gameStateEfficiency')
                                            .map((m) => (
                                                <MenuItem sx={{ minHeight: '20px' }} key={m.metric} value={m.metric}>
                                                    {m.abbr}
                                                </MenuItem>
                                            ))}
                                        <ListSubheader sx={listSubHeaderStyles}>Game State Selection</ListSubheader>
                                        {plotMetrics
                                            .filter((x) => x.category === 'gameStateSelection')
                                            .map((m) => (
                                                <MenuItem sx={{ minHeight: '20px' }} key={m.metric} value={m.metric}>
                                                    {m.abbr}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                )}
                            </FormControl>
                        )}
                        {!isMobile && (
                            <Button
                                variant="outlined"
                                onClick={() => setShowAverages(!showAverages)}
                                endIcon={<PercentIcon />}
                                sx={{ height: '33px', width: '98px' }}
                                disabled={loading}
                            >
                                {showAverages ? 'Hide' : 'Show'}
                            </Button>
                        )}
                    </Box>
                    <Box>
                        {plotType === 'GAME_LOG' && showSubCategories && (
                            <FormControl size="small">
                                <InputLabel id="tier-label">Game Rolling Average</InputLabel>
                                <Select
                                    label="Game Rolling Average"
                                    name="gameRollingAverage"
                                    onChange={(e) => {
                                        if (e.target.value === 'None') {
                                            setShowRollingAverage(false)
                                            setRollNum('None' as unknown as number)
                                        } else {
                                            setShowRollingAverage(true)
                                            setRollNum(e.target.value as unknown as number)
                                        }
                                    }}
                                    value={rollNum}
                                    size="small"
                                    sx={{
                                        height: '33px',
                                        minWidth: '150px',
                                        fontStyle: rollNum === 'None' ? 'italic' : undefined,
                                    }}
                                >
                                    {['None', 5, 10, 15, 20].map((d) => (
                                        <MenuItem
                                            key={d}
                                            value={d}
                                            sx={{ fontStyle: d === 'None' ? 'italic' : undefined }}
                                        >
                                            {d}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                        <Tooltip title="Copy Link">
                            <IconButton
                                onClick={async () => {
                                    const href = window.location.toString()
                                    if (href.includes('/players')) {
                                        const server = href.split('/players')[0]
                                        const query = {
                                            ...URLSearchParamsToObject(searchParams),
                                            table: undefined,
                                            metric,
                                        }
                                        const urlString = `${server}${pathname}?${objectToURLSearchParams(query)}`
                                        await navigator.clipboard.writeText(`${urlString}`)
                                    }
                                }}
                                sx={{ displayPrint: 'none', marginLeft: 1, marginBottom: 1 }}
                            >
                                <LinkIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Box>
            </Box>
            <svg
                ref={plotRef}
                width={svgWidth}
                height={svgHeight}
                style={{
                    backgroundColor: 'white',
                    opacity: loading ? 0.3 : 1,
                }}
                className="stats-svg"
            />
        </Card>
    )
}
export default TimeSeriesPlot
